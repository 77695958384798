import React, { useEffect, useState, useRef } from "react";
import {
  fetchRates,
  updateRate,
  getPayinsEdit,
  getPayoutsEdit,
  deleteRate,
  defaultRate,
  search,
  getRateManagers,
  viewRate,
} from "../../../store/actions/admin/rates";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../../components/pagination/pagination";
import { Modal, Select, Spin } from "antd";
import * as Yup from "yup";
import { fetchCountries } from "../../../store/actions/general";
import FormikError from "../../../components/partials/formikError";
import { Formik } from "formik";
import { useSearchParams } from "react-router-dom";

export default function List() {
  const dispatch = useDispatch();
  const {
    savedRates,
    fetchingRates,
    rates,
    searching,
    updatingRates,
    payinsEdit,
    payoutsEdit,
    fetchingPayins,
    fetchingPayouts,
    updatedRates,
    deleting,
    deleted,
    settingDefault,
    setDefault,
    rateManagers,
  } = useSelector((state) => state.rates);

  const { msg } = useSelector((state) => state.error);

  const findSelectedRateManager = (id) => {
    const manager = rateManagers?.find((manager) => manager.uuid === id);
    if (manager) {
      setSelectedManager(manager);
    }
    return manager;
  };
  const [showEdit, setShowEdit] = useState({ show: false, data: {} });
  const [fromCountry, setFromCountry] = useState("");
  const [toCountry, setToCountry] = useState("");
  const [selectedPayouts, setSelectedPayouts] = useState([]);
  const [total, setTotal] = useState(0);
  const [defaultPayout, setDefaultPayout] = useState();
  const formikRef = useRef();
  const [initialVals, setInitialVals] = useState({});
  const [selectedPayins, setSelectedPayins] = useState([]);
  const [bnToDelete, setBnToDelete] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDefaultModal, setShowDefaultModal] = useState(false);
  const [selectedPayoutIds, setSelectedPayoutIds] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(null);
  const [selectedManager, setSelectedManager] = useState("");

  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");
  const toggleModal = (rate = {}) => {
    if (rate?.from_country?.id) {
      dispatch(getPayinsEdit(rate?.from_country?.id, rate.id));
      dispatch(getPayoutsEdit(rate?.to_country?.id, rate.id));
    }

    let payinsArr = [];
    rate?.payins?.map((el) => {
      payinsArr = [...payinsArr, el.id];
      return true;
    });
    setSelectedPayins(payinsArr);

    let payoutsArr = [];
    let seltectPayoutIdsArr = [];

    rate?.payouts?.map((el) => {
      seltectPayoutIdsArr = [...seltectPayoutIdsArr, el.id];
      if (el.pivot.is_default) setDefaultPayout(el.id);
      payoutsArr = [
        ...payoutsArr,
        {
          payout_id: el.id,
          auto_settle: el.pivot.auto_settle,
          is_default: el.pivot.is_default,
        },
      ];
      return true;
    });

    setSelectedPayoutIds(seltectPayoutIdsArr);
    setSelectedPayouts(payoutsArr);
    setFromCountry(rate?.from_country?.id);
    setToCountry(rate?.to_country?.id);
    setInitialVals({
      rate_id: rate?.id,
      from_country: rate?.from_country?.id,
      to_country: rate?.to_country?.id,
      buy: !!selectedManager ? selectedManager?.rate : rate?.buy,
      rate_manager_id: rate?.manager?.uuid ?? "",
      fee: rate?.fee,
      additional_rate: rate?.additional_rate,
      maximum: rate?.maximum?.split(",")?.join(""),
      minimum: rate?.minimum?.split(",")?.join(""),
      promorate: rate?.promorate,
      times: rate?.times,
      islocal: rate?.is_local,
      payin_ids: payinsArr,
      payouts: payoutsArr,
      deactivate: rate?.deactivated_at ? true : false,
      default: rate?.is_default ? true : false,
      referral_bonus_amount: rate?.referral_bonus_amount ?? "",
      referal_bonus_outbound_limit: rate?.referal_bonus_outbound_limit ?? "",
    });
    setShowEdit({ show: !showEdit.show, data: rate });
  };

  useEffect(() => {
    if (searchParam) {
      dispatch(
        search({
          param: searchParam,
          page: pageParam,
        })
      );
    } else {
      dispatch(fetchRates(pageParam));
    }
    setCurrentPage(pageParam);
  }, [pageParam, searchParam]);

  useEffect(() => {
    if (updatedRates) {
      setShowEdit({ show: false, data: {} });
    }
    if (deleted) {
      setShowDeleteModal(false);
      dispatch(fetchRates());
    }
    if (setDefault) {
      setShowDefaultModal(false);
      dispatch(fetchRates());
    }
  }, [updatedRates, deleted, setDefault]);

  useEffect(() => {
    if (savedRates) dispatch(fetchRates());
  }, [savedRates]);

  useEffect(() => {
    if (toCountry && fromCountry) {
      dispatch(getRateManagers({ toCountry, fromCountry }));
    }
  }, [toCountry, fromCountry]);
  const toggleDeleteModal = (id) => {
    setBnToDelete(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleDefaultModal = (id) => {
    setBnToDelete(id);
    setShowDefaultModal(!showDeleteModal);
  };

  const deleteBnHandler = () => {
    dispatch(deleteRate(bnToDelete));
  };
  const defaultBnHandler = () => {
    dispatch(defaultRate(bnToDelete));
  };

  const user = JSON.parse(localStorage.getItem("authRes"));
  const permissions = user.permissions;
  return (
    <>
      <div className="table-responsive">
        {fetchingRates || searching ? <Spin /> : ""}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">From Country</th>
              <th className="text-muted text-uppercase">Rate</th>
              <th className="text-muted text-uppercase">To Country</th>
              <th className="text-muted text-uppercase">Fee</th>
              <th className="text-muted text-uppercase">min|max</th>
              <th className="text-muted text-uppercase">created at</th>
              <th className="text-muted text-uppercase">action</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {rates.data?.map((el, i) => {
              let count = i + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${i}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }
              return (
                <tr className="border-bottom" key={i}>
                  <td>{count}</td>
                  <td>
                    <span
                      className={`flag-icon flag-icon-${el.from_country.iso2} flag-icon-squared`}
                    ></span>
                    &nbsp; {el.from_country.name}
                  </td>
                  <td>{el.buy}</td>
                  <td>
                    <span
                      className={`flag-icon flag-icon-${el.to_country.iso2} flag-icon-squared`}
                    ></span>
                    &nbsp; {el.to_country.name}
                  </td>
                  <td>{el.fee}</td>
                  <td>
                    {el.minimum}|{el.maximum}
                  </td>
                  <td>{el.created_at}</td>
                  <td>
                    <div className="dropdown">
                      <a
                        className="btn dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </a>
                      <ul className="dropdown-menu shadow-sm rounded">
                        {permissions["edit rate"] && (
                          <>
                            <li>
                              <a
                                className="dropdown-item py-2"
                                href="#editRate"
                                data-bs-toggle="modal"
                                onClick={(e) => {
                                  e.preventDefault();
                                  toggleModal(el);
                                }}
                              >
                                View &amp; Edit
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item py-2"
                                href="#defaultModal"
                                onClick={() => {
                                  toggleDefaultModal(el.id);
                                }}
                              >
                                Set as default
                              </a>
                            </li>
                          </>
                        )}
                        {permissions["delete rate"] && (
                          <li>
                            <a
                              className="dropdown-item py-2"
                              href="#deleteModal"
                              onClick={() => {
                                toggleDeleteModal(el.id);
                              }}
                            >
                              Trash
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {rates?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (rates !== null && rates?.meta?.per_page) {
              if (searchParam) {
                setSearchParams({
                  param: searchParam,
                  page,
                });
                dispatch(
                  search({
                    param: searchParam,
                    page: page,
                  })
                );
              } else {
                setSearchParams({ page });
                dispatch(fetchRates(page));
              }
            }
          }}
          data={rates}
        />
      )}

      {/* Update Modal Goes Here */}
      <Modal
        visible={showEdit.show}
        footer={false}
        style={{
          top: 20,
        }}
        width={"80%"}
        onCancel={() => {
          toggleModal();
        }}
      >
        <h5 className="fw-bold">Update Local Rate</h5>
        <Formik
          initialValues={initialVals}
          innerRef={formikRef}
          enableReinitialize={true}
          validationSchema={Yup.object({
            from_country: Yup.string().required("Select country"),
            to_country: Yup.string().required("Select country"),
            buy: Yup.number()
              .required("Rate is required")
              .typeError("Invalid input"),
            rate_manager_id: Yup.string(),
            fee: Yup.number()
              .required("Fee is required")
              .typeError("Invalid input"),
            additional_rate: Yup.number()
              .required("Additional rate is required")
              .typeError("Invalid input"),
            maximum: Yup.number()
              .required("Maximum is required")
              .typeError("Invalid input"),
            minimum: Yup.number()
              .required("Minimum is required")
              .typeError("Invalid input"),
            promorate: Yup.number()
              .required("Promo rate is required")
              .typeError("Invalid input"),
            times: Yup.number()
              .required("Times is required")
              .typeError("Invalid input"),
            payouts: Yup.array()
              .min(1, "Select at least one payout")
              .of(
                Yup.object().shape({
                  payout_id: Yup.string().required(""),
                  auto_settle: Yup.string().required(""),
                  is_default: Yup.string().required(""),
                })
              ),
            payin_ids: Yup.array().min(1, "Select at least one payin"),
            referral_bonus_amount: Yup.number().typeError("Invalid input"),
            referal_bonus_outbound_limit:
              Yup.number().typeError("Invalid input"),
          })}
          onSubmit={(values) => {
            dispatch(updateRate(values));
          }}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit} id="rateForm">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>From-Amount</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        value="1"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Select From Country</label>
                      <Select
                        showSearch
                        filterOption={(input, option) => {
                          return option.name
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                        placeholder="Select country"
                        className="form-control min-height"
                        onChange={(value) => {
                          dispatch(getPayinsEdit(value));
                          setFromCountry(value);
                          props.setValues({
                            ...props.values,
                            from_country: value,
                            payin_ids: [],
                          });
                        }}
                        value={props.values.from_country}
                        defaultValue={initialVals.from_country}
                      >
                        {JSON.parse(
                          localStorage.getItem("cosmo_countries")
                        )?.map((el) => {
                          return (
                            <Select.Option
                              key={el.id}
                              id={el.id}
                              value={el.id}
                              countryCode={el.phonecode}
                              name={el.name}
                            >
                              <span
                                className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                              ></span>
                              {"\u00A0"}
                              {el.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      {props.touched.from_country &&
                        props.errors.from_country && (
                          <FormikError msg={props.errors.from_country} />
                        )}
                      <FormikError msg={msg?.from_country ?? ""} />
                    </div>
                  </div>

                  {/* {permissions["can_change_rate"] && ( */}
                  <div className="d-md-flex col-md-6 gap-4">
                    <div className="form-group w-100 mb-3">
                      <label>Select Rate Manager</label>
                      <Select
                        placeholder="Select a rate manager"
                        className="form-control min-height"
                        name="rate_manager_id"
                        onChange={(value) => {
                          props.setValues({
                            ...props.values,
                            rate_manager_id: value,
                          });
                        }}
                        value={props.values.rate_manager_id}
                        defaultValue={initialVals.rate_manager_id}
                      >
                        {rateManagers?.map((manager) => (
                          <Select.Option
                            value={
                              manager.display_name === "Manual"
                                ? ""
                                : manager.uuid
                            }
                          >
                            {manager.display_name}
                          </Select.Option>
                        ))}
                      </Select>
                      {props.touched.from_country &&
                        props.errors.from_country && (
                          <FormikError msg={props.errors.from_country} />
                        )}
                      <FormikError msg={msg?.from_country ?? ""} />
                    </div>
                    <div className="form-group w-100 mb-3">
                      <label>Enter Rate</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        name="buy"
                        disabled={
                          !!findSelectedRateManager(
                            props.values.rate_manager_id
                          )
                        }
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.buy}
                      />
                      {props.touched.buy && props.errors.buy && (
                        <FormikError msg={props.errors.buy} />
                      )}
                      <FormikError msg={msg?.buy ?? ""} />
                    </div>
                  </div>
                  {/* )} */}
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Select To Country</label>
                      <Select
                        showSearch
                        filterOption={(input, option) => {
                          return option.name
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                        placeholder="Select country"
                        className="form-control min-height"
                        onChange={(value) => {
                          dispatch(getPayoutsEdit(value));
                          setToCountry(value);
                          props.setValues({
                            ...props.values,
                            to_country: value,
                          });
                        }}
                        defaultValue={initialVals.to_country}
                        value={props.values.to_country}
                        // disabled={fromCountry ? false : true}
                      >
                        {JSON.parse(
                          localStorage.getItem("cosmo_countries")
                        )?.map((el) => {
                          return (
                            <Select.Option
                              key={el.id}
                              id={el.id}
                              countryCode={el.phonecode}
                              name={el.name}
                              value={el.id}
                            >
                              <span
                                className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                              ></span>
                              {"\u00A0"}
                              {el.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      {props.touched.to_country && props.errors.to_country && (
                        <FormikError msg={props.errors.to_country} />
                      )}
                      <FormikError msg={msg?.to_country ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Enter Fee </label>
                      {/* <label ></label>Enter Fee ({total})</label> */}
                      <input
                        type="text"
                        className="form-control min-height"
                        onChange={(e) => {
                          props.setValues({
                            ...props.values,
                            fee: e.target.value,
                          });
                          // setTotal(total + Number(props.values.fee))
                        }}
                        onBlur={props.handleBlur}
                        name="fee"
                        value={props.values.fee}
                      />
                      {props.touched.fee && props.errors.fee && (
                        <FormikError msg={props.errors.fee} />
                      )}
                      <FormikError msg={msg?.fee ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Additional Rate</label>
                      {/* <label ></label>Enter Fee ({total})</label> */}
                      <input
                        type="text"
                        className="form-control min-height"
                        onChange={(e) => {
                          props.setValues({
                            ...props.values,
                            additional_rate: e.target.value,
                          });
                          // setTotal(total + Number(props.values.fee))
                        }}
                        onBlur={props.handleBlur}
                        name="addtional_rate"
                        value={props.values.additional_rate}
                      />
                      {props.touched.additional_rate &&
                        props.errors.additional_rate && (
                          <FormikError msg={props.errors.additional_rate} />
                        )}
                      <FormikError msg={msg?.additional_rate ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Min. sender's amount</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        name="minimum"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.minimum}
                      />
                      {props.touched.minimum && props.errors.minimum && (
                        <FormikError msg={props.errors.minimum} />
                      )}
                      <FormikError msg={msg?.minimum ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Max. sender's amount</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        name="maximum"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.maximum}
                      />
                      {props.touched.maximum && props.errors.maximum && (
                        <FormikError msg={props.errors.maximum} />
                      )}
                      <FormikError msg={msg?.maximum ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>New user rate (Promorate)</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        name="promorate"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.promorate}
                      />
                      {props.touched.promorate && props.errors.promorate && (
                        <FormikError msg={props.errors.promorate} />
                      )}
                      <FormikError msg={msg?.promorate ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Referral Bonus Amount </label>
                      {/* <label htmlFor>Enter Fee ({total})</label> */}
                      <input
                        type="number"
                        className="form-control min-height"
                        onChange={(e) => {
                          props.setValues({
                            ...props.values,
                            referral_bonus_amount: e.target.value,
                          });
                        }}
                        value={props.values.referral_bonus_amount}
                        onBlur={props.handleBlur}
                        name="referral_bonus_amount"
                      />
                      {props.touched.referral_bonus_amount &&
                        props.errors.referral_bonus_amount && (
                          <FormikError
                            msg={props.errors.referral_bonus_amount}
                          />
                        )}
                      <FormikError msg={msg?.referral_bonus_amount ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Referral Outbound Bonus Limit </label>
                      {/* <label htmlFor>Enter Fee ({total})</label> */}
                      <input
                        value={props.values.referal_bonus_outbound_limit}
                        type="number"
                        className="form-control min-height"
                        onChange={(e) => {
                          props.setValues({
                            ...props.values,
                            referal_bonus_outbound_limit: e.target.value,
                          });
                        }}
                        onBlur={props.handleBlur}
                        name="referal_bonus_outbound_limit"
                      />
                      {props.touched.referal_bonus_outbound_limit &&
                        props.errors.referal_bonus_outbound_limit && (
                          <FormikError
                            msg={props.errors.referal_bonus_outbound_limit}
                          />
                        )}
                      <FormikError
                        msg={msg?.referal_bonus_outbound_limit ?? ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>New user times</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        name="times"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.times}
                      />
                      {props.touched.times && props.errors.times && (
                        <FormikError msg={props.errors.times} />
                      )}
                      <FormikError msg={msg?.times ?? ""} />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-check form-switch mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="_default"
                      name="default"
                      checked={props.values.default}
                      onChange={(e) => {
                        if (e.target.checked) {
                          props.setValues({
                            ...props.values,
                            default: true,
                          });
                        } else {
                          props.setValues({
                            ...props.values,
                            default: false,
                          });
                        }
                      }}
                      onBlur={props.handleBlur}
                    />

                    <label className="form-check-label" htmlFor="_default">
                      Default
                    </label>
                  </div>
                  {props.touched.default && props.errors.default && (
                    <FormikError msg={props.errors.default} />
                  )}
                  <FormikError msg={msg?.default ?? ""} />
                </div>
                <div className="col-md-6">
                  <div className="form-check form-switch mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="deactivate"
                      name="deactivate"
                      checked={props.values.deactivate}
                      onChange={(e) => {
                        if (e.target.checked) {
                          props.setValues({
                            ...props.values,
                            deactivate: true,
                          });
                        } else {
                          props.setValues({
                            ...props.values,
                            deactivate: false,
                          });
                        }
                      }}
                      onBlur={props.handleBlur}
                    />

                    <label className="form-check-label" htmlFor="deactivate">
                      Deactivate
                    </label>
                  </div>
                  {props.touched.deactivate && props.errors.deactivate && (
                    <FormikError msg={props.errors.deactivate} />
                  )}
                  <FormikError msg={msg?.deactivate ?? ""} />
                </div>

                <div className="row">
                  {/* payin */}
                  <div className="col-md-4">
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <thead>
                          <tr className="border-top border-bottom">
                            <th />
                            <th className="text-uppercase text-muted">
                              Payins
                            </th>
                            <th className="text-uppercase text-muted">fee</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payinsEdit?.all_payins?.map((el, i) => {
                            return (
                              <tr className="border-bottom" key={i}>
                                <td>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={el.id}
                                      name="payout_option"
                                      id="payin1check"
                                      checked={selectedPayins?.includes(el?.id)}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setTotal(total + Number(el.fee));
                                          setSelectedPayins([
                                            ...selectedPayins,
                                            Number(e.target.value),
                                          ]);
                                          props.setValues({
                                            ...props.values,
                                            payin_ids: [
                                              ...props.values.payin_ids,
                                              e.target.value,
                                            ],
                                          });
                                        } else {
                                          setTotal(total - Number(el.fee));
                                          let arr =
                                            props.values.payin_ids.filter(
                                              (el) => {
                                                return (
                                                  Number(el) !==
                                                  Number(e.target.value)
                                                );
                                              }
                                            );
                                          props.setValues({
                                            ...props.values,
                                            payin_ids: arr,
                                          });
                                          let ar2 = selectedPayins?.filter(
                                            (el) =>
                                              Number(el) !==
                                              Number(e.target.value)
                                          );
                                          setSelectedPayins(ar2);
                                        }
                                      }}
                                    />
                                    <label
                                      className="form-check-label text-muted"
                                      htmlFor="payin1check"
                                    />
                                  </div>
                                </td>
                                <td>{el.name}</td>
                                <td>+ {el.fee}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {fetchingPayins && <Spin />}
                      {props.touched.payin_ids && props.errors.payin_ids && (
                        <FormikError msg={props.errors.payin_ids} />
                      )}
                      <FormikError msg={msg?.payin_ids ?? ""} />
                    </div>
                  </div>
                  {/* payout */}
                  <div className="col-md-8">
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <thead>
                          <tr className="border-top border-bottom">
                            <th />
                            <th
                              className="text-uppercase text-muted"
                              colSpan={2}
                            >
                              payouts
                            </th>
                            <th className="text-uppercase text-muted">
                              Auto Settle
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {payoutsEdit?.all_payouts?.map((el, i) => {
                            let settleStr = "";
                            if (selectedPayoutIds?.includes(el.id)) {
                              const autoSettleVal = props.values.payouts.find(
                                (f) => {
                                  return Number(f.payout_id) === Number(el.id);
                                }
                              );

                              if (autoSettleVal?.auto_settle === "no") {
                                settleStr = "false";
                              }

                              if (autoSettleVal?.auto_settle === "yes") {
                                settleStr = "true";
                              }
                            }
                            return (
                              <tr className="border-bottom" key={i}>
                                <td>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="payout_option"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setSelectedPayoutIds([
                                            ...selectedPayoutIds,
                                            Number(e.target.value),
                                          ]);
                                          let payoutObj = {
                                            payout_id: Number(e.target.value),
                                            auto_settle: "no",
                                            is_default: true,
                                          };

                                          if (props.values.payouts.length < 1) {
                                            setDefaultPayout(e.target.value);
                                          }

                                          if (props.values.payouts.length > 0) {
                                            payoutObj = {
                                              payout_id: Number(e.target.value),
                                              auto_settle: "no",
                                              is_default: false,
                                            };
                                          }

                                          props.setValues({
                                            ...props.values,
                                            payouts: [
                                              ...props.values.payouts,
                                              payoutObj,
                                            ],
                                          });
                                        } else {
                                          if (
                                            Number(e.target.value) ===
                                            Number(defaultPayout)
                                          )
                                            setDefaultPayout(0);
                                          let arr = props.values.payouts.filter(
                                            (el) =>
                                              Number(el.payout_id) !==
                                              Number(e.target.value)
                                          );
                                          props.setValues({
                                            ...props.values,
                                            payouts: arr,
                                          });

                                          let ar2 = selectedPayoutIds?.filter(
                                            (el) =>
                                              Number(el) !==
                                              Number(e.target.value)
                                          );
                                          setSelectedPayoutIds(ar2);
                                        }
                                      }}
                                      value={el.id}
                                      id={`payoutcheck${el.id}`}
                                      checked={selectedPayoutIds?.includes(
                                        el.id
                                      )}
                                    />
                                    <label
                                      className="form-check-label text-muted"
                                      htmlFor={`payoutcheck${el.id}`}
                                    />
                                  </div>
                                </td>
                                <td>{el.name}</td>
                                <td>
                                  <div className="form-check form-check-inline">
                                    <label
                                      className="form-check-label text-muted"
                                      htmlFor={`payout1Radio${el.id}`}
                                    >
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value={el.id}
                                        name="payout_option"
                                        id={`payout1Radio${el.id}`}
                                        disabled={
                                          selectedPayoutIds?.includes(el.id)
                                            ? false
                                            : true
                                        }
                                        checked={
                                          Number(defaultPayout) ===
                                          Number(el.id)
                                        }
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            // make the checked radio the default select
                                            setDefaultPayout(
                                              Number(e.target.value)
                                            );
                                            if (
                                              selectedPayoutIds?.includes(
                                                Number(e.target.value)
                                              )
                                            ) {
                                              /* if the checked is among the previously selected payouts make it default 
                                                and make others is_default false
                                              */
                                              let newArr =
                                                props.values.payouts.map(
                                                  (el) => {
                                                    if (
                                                      Number(el.payout_id) ===
                                                      Number(e.target.value)
                                                    ) {
                                                      return {
                                                        ...el,
                                                        is_default: true,
                                                      };
                                                    } else {
                                                      return {
                                                        ...el,
                                                        is_default: false,
                                                      };
                                                    }
                                                  }
                                                );

                                              // update this props coming from formik
                                              props.setValues({
                                                ...props.values,
                                                payouts: newArr,
                                              });
                                            } else {
                                              // if not in previously selected payouts add it
                                              // setSelectedPayoutIds([
                                              //   ...selectedPayoutIds,
                                              //   e.target.value,
                                              // ]);

                                              // make the is_default of others false too
                                              let arr =
                                                props.values.payouts.map(
                                                  (el) => {
                                                    return {
                                                      ...el,
                                                      is_default: false,
                                                    };
                                                  }
                                                );

                                              let newArr = [
                                                ...arr,
                                                {
                                                  payout_id: e.target.value,
                                                  auto_settle: "no",
                                                  is_default: true,
                                                },
                                              ];

                                              // add this new value to the formik props
                                              props.setValues({
                                                ...props.values,
                                                payouts: newArr,
                                              });
                                            }
                                          }
                                        }}
                                      />
                                      Select as default
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <select
                                    name=""
                                    className="form-select"
                                    disabled={
                                      selectedPayoutIds?.includes(el.id)
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        let newArr = props.values.payouts.map(
                                          (el) => {
                                            if (
                                              Number(el.payout_id) ===
                                              Number(
                                                e.target.options[
                                                  e.target.selectedIndex
                                                ].id
                                              )
                                            ) {
                                              return {
                                                ...el,
                                                auto_settle: e.target.value,
                                              };
                                            }
                                            return el;
                                          }
                                        );
                                        props.setValues({
                                          ...props.values,
                                          payouts: newArr,
                                        });
                                      }
                                    }}
                                    defaultValue={settleStr}
                                  >
                                    <option value="">--Select--</option>
                                    <option value="true" id={el.id}>
                                      yes
                                    </option>
                                    <option value="false" id={el.id}>
                                      no
                                    </option>
                                  </select>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {fetchingPayouts && <Spin />}
                      {props.touched.payouts && props.errors.payouts && (
                        <FormikError msg={props.errors.payouts} />
                      )}
                      <FormikError msg={msg?.payouts ?? ""} />
                    </div>
                  </div>
                </div>

                <div className="text-end">
                  <button
                    className="btn btn-outline-red ms-2 px-4"
                    onClick={() => {
                      toggleModal();
                    }}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-red ms-2 px-4"
                    disabled={updatingRates}
                    type="submit"
                  >
                    Submit {updatingRates && <Spin />}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>

      {/* Delete Modal */}
      <Modal
        visible={showDeleteModal}
        onCancel={() => {
          toggleDeleteModal();
        }}
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        closable={false}
        footer={false}
        // width="25%"
      >
        <div className="modal-body text-center">
          <img
            src="../assets/images/components/rafiki.png"
            alt="delete icon"
            className="img-fluid"
          />
          <p>Are you sure you want to delete this role</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              toggleDeleteModal();
            }}
            disabled={deleting}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={deleting}
            onClick={() => {
              deleteBnHandler();
            }}
          >
            Delete {deleting && <Spin />}
          </button>
        </div>
      </Modal>

      {/* Set default Modal */}
      <Modal
        visible={showDefaultModal}
        onCancel={() => {
          toggleDefaultModal();
        }}
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        closable={false}
        footer={false}
        // width="25%"
      >
        <div className="modal-body text-center">
          <img
            src="../assets/images/components/rafiki.png"
            alt="delete icon"
            className="img-fluid"
          />
          <p>Are you sure you want to make this role default role?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              setShowDefaultModal(false);
            }}
            disabled={settingDefault}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={settingDefault}
            onClick={() => {
              defaultBnHandler();
            }}
          >
            Set default {settingDefault && <Spin />}
          </button>
        </div>
      </Modal>
    </>
  );
}
